import { useCallback, useContext } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import CollectionCover from '../images/cover.png'
import Wrapper from "./Wrapper";
import { NavLink } from "react-router-dom";
import { NoDataFound, Slider } from "../Library/Module";
import SimpleSlider from "../components/Slider";


import ImageCertificate from "../images/certificate.jpg"
import CategoryBox from "./CategoryBox";

interface HomeProps {
    link: string;
}

const HomePage = ({ link }: HomeProps): JSX.Element => {
    const { mainCategory, breadcrumb, updateBreadcrumb, updateCatlogData, catlogData } = useContext(ThemeContext)

    return (
        <>
            <SimpleSlider></SimpleSlider>
            <div className="intro">
                <div className="container pt-4 pb-2">
                    <div className="col-md-12">
                        <h1>At National Homoeo Laboratory, we specialize in the production of classical homoeopathic medicines, adhering to traditional methods and uncompromising quality standards. </h1>
                        <h4>Our team of experienced homoeopathic experts and cutting-edge manufacturing facility ensure the efficacy and effectiveness of our remedies, addressing a wide range of health concerns. All of our products are manufactured using the finest raw materials, adhere to strict quality control measures, and are produced in compliance with homeopathic pharmacopeia guidelines. Our product line includes:</h4>
                    </div>
                </div>
            </div>

            <CategoryBox></CategoryBox>

            {/* <div className="container pt-4 pb-2">
                <div className="new_collection">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="inner">
                                <h5>Single Remedies:</h5>
                                <p>We manufacture individual remedies that target specific ailments, allowing patients to address their health concerns directly.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="inner">
                                <h5>Combination Remedies:</h5>
                                <p>We also provide combination remedies that synergistically combine multiple ingredients to address common health issues such as allergies, digestive problems, and stress.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="inner">
                                <h5>Mother Tinctures:</h5>
                                <p>Our range includes mother tinctures, which are concentrated herbal extracts used for various therapeutic purposes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="intro">
                <div className="container pt-4 pb-2">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6">
                            <img src={ImageCertificate} alt="" title="" />
                        </div>
                        <div className="col-md-6">
                            <p>With a legacy of 75 years, National Homoeo Laboratory is a trusted name in the global homeopathic medicine market. We produce high-quality classical homoeopathic remedies, committed to providing accessible alternative healthcare solutions and contributing to the industry's growth.</p>
                            <p>As the global homeopathic medicine market continues to expand due to increased demand for natural and holistic healthcare options, National Homoeo Laboratory is poised to lead the way with its commitment to producing top-quality classical homoeopathic remedies. </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default HomePage;
