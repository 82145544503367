import React, { Component } from "react";
import Slider from "react-slick"
import { Animated } from "react-animated-css";
import Image4 from '../images/bannerNew.png'
export default class SimpleSlider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000

        };
        return (
            <div className="sliderMain">
                <Slider {...settings}>
                    <div className="sliderImage" >
                        <div className="imageSlider">
                        <img src={Image4} alt="" title="" />
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                       
                                        <div className="banner">
                                            <div className="banner_text">
                                                <Animated animationInDelay={100} animationIn="fadeInUp" animationOut="fadeOut" isVisible={true}>
                                                    <h3>With a rich history of <strong>75 Years </strong> Our goal is to provide accessible classical homoeopathic remedies </h3>
                                                </Animated>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Slider>
            </div>
        );
    }
}
