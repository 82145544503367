import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ProductGrid from './ProductGrid'
import Wrapper from "./Wrapper";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, NoDataFound, SideDrawer, Slider } from "../Library/Module";
import { getCollection } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";
import subFooter from '../images/subFooter.png';
import ContactForm from "../components/ContactForm";
import qualityImg from '../images/quality.png';
interface HomeProps {
    link: string;
}

const HomeopathicProductCatalog = ({ link }: HomeProps): JSX.Element => {
    const { mainCategory, updateBgColor, breadcrumb, updateBreadcrumb, catlogData, productList } = useContext(ThemeContext)
    const { category, subCategory, product_title } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [selectedData, setSelectedData] = useState<any>('')
    const [bgImage, setBgImage] = useState<any>('')



    const selectedProduct = useCallback((data) => {
        setSelectedData(data)
        setOpen(true)
    }, [])

    const CloseDrawer = () => {
        setOpen(false)
    };

    const applyBtn = useCallback((data) => {
        setBgImage(data?.image)
        updateBgColor?.(data?.image)
    }, [updateBgColor])

    const closepopup = useCallback(() => {
        setSelectedData('')
    }, [])

    const catagoryData = useMemo(() => {
        if (productList?.length !== 0) {
            return productList?.find((item: any) => {
                return item?.slug === product_title
            })
        }
    }, [productList, product_title])

    const categoryTitle = useMemo(() => mainCategory?.find((i: any) => i?.slug === category), [category, mainCategory])
    console.log("categoryTitle", categoryTitle?.title)

    const categorySubTitle = useMemo(() => catlogData?.find((i: any) => i?.slug === subCategory), [catlogData, subCategory])
    console.log("categorySubTitle", categorySubTitle?.title)

    useEffect(() => {
        const data = [
            { "name": "Home", "link": "/", "icon": "" },
            { "name": "Product Category", "link": "/category", "icon": "" },
            { "name": categoryTitle?.title, "link": `/category/${category}`, "icon": "" },
            { "name": categorySubTitle?.title, "link": `/category/${category}/${subCategory}`, "icon": "" },
            { "name": catagoryData?.title, "link": `/category/${category}/${subCategory}/${product_title}`, "icon": "" }

        ]
        updateBreadcrumb(data)
    }, [catagoryData?.title, category, categorySubTitle?.title, categoryTitle?.title, product_title, subCategory, updateBreadcrumb])



    const Inquiry = () => {
        setOpen(true)
    }

    return (
        <>
            <div className="productDetails">
                <div className={`banner banner2 ${category}`}>
                    <div className="container">
                        <div className="banner_text">
                            <h3 className="changeCase">{catagoryData?.title}</h3>
                        </div>
                    </div>
                </div>
                <div className="">
                    {breadcrumb}
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>
                                <p><span>SI.</span></p>
                                <p><span>No.</span></p>
                            </th>
                            <th>
                                <p><span>Name of Medicine</span></p>
                            </th>
                            <th>
                                <p><span>Synonym</span></p>
                            </th>
                            <th>
                                <p><span>Other Potencies</span></p>
                            </th>
                            <th>
                                <p><span>Catgory of </span><span>.T.</span></p>
                            </th>
                            <th>
                                <p><span>Cate o?' </span><span>ofT </span><span>I</span></p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p><span>3x</span><span>, </span><span>6x</span></p>
                            </td>
                            <td>
                                <p><span>6</span><span>,</span></p>
                                <p><span>12</span></p>
                            </td>
                            <td>
                                <p><span>30</span></p>
                            </td>
                            <td>
                                <p><span>200</span></p>
                            </td>
                            <td>
                                <p><span>1M</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>3x</span></p>
                            </td>
                            <td>
                                <p><span>6x</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>43</span></p>
                            </td>
                            <td>
                                <p><span>ACIDUM URICUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>44</span></p>
                            </td>
                            <td>
                                <p><span>ACONITINE</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>Ji:</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>,f</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>45</span></p>
                            </td>
                            <td>
                                <p><span>ACONITUM CAMMARUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>46</span></p>
                            </td>
                            <td>
                                <p><span>ACONITUM FEROX</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>47</span></p>
                            </td>
                            <td>
                                <p><span>ACONITUM FISCHER!</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>48</span></p>
                            </td>
                            <td>
                                <p><span>ACONITUM L</span><span>YCOCTONUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>49</span></p>
                            </td>
                            <td>
                                <p><span>ACONITUM NAPELLUS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>#</span></em></p>
                                <p><span>"</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>50</span></p>
                            </td>
                            <td>
                                <p><span>ACONITUM-E-RADICE</span></p>
                            </td>
                            <td>
                                <p><span>Aconitum radix</span></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>51</span></p>
                            </td>
                            <td>
                                <p><span>ACORUS CALAMUS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>52</span></p>
                            </td>
                            <td>
                                <p><span>ACTAEA RACEMOSA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>f</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>53</span></p>
                            </td>
                            <td>
                                <p><span>ACTAEA SPICATA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>f</span></p>
                            </td>
                            <td>
                                <p><em><span>,f</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>54</span></p>
                            </td>
                            <td>
                                <p><span>ADAMUS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>2i</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>55</span></p>
                            </td>
                            <td>
                                <p><span>ADONIS VERNALIS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>56</span></p>
                            </td>
                            <td>
                                <p><span>ADONIDINUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>Ji:</span></em></p>
                            </td>
                            <td>
                                <p><em><span>Ji:</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>if</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>57</span></p>
                            </td>
                            <td>
                                <p><span>ADRENALIN CHLORID</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>58</span></p>
                            </td>
                            <td>
                                <p><span>ADRENALINUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>J:f</span></p>
                            </td>
                            <td>
                                <p><em><span>f</span></em></p>
                            </td>
                            <td>
                                <p><em><span>,f</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>c;,/</span></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>59</span></p>
                            </td>
                            <td>
                                <p><span>AEGLE FOLIA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>cf</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>60</span></p>
                            </td>
                            <td>
                                <p><span>AEGLE MARMELOS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>,J</span></em></p>
                                <p><em><span>if</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>,J</span></em></p>
                                <p><em><span>if</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>61</span></p>
                            </td>
                            <td>
                                <p><span>AESCULUS GLABRA</span></p>
                            </td>
                            <td>
                                <p><span>Pavia Glabra</span></p>
                            </td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>62</span></p>
                            </td>
                            <td>
                                <p><span>AESCULUS HIPPOCASTANUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>cl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>63</span></p>
                            </td>
                            <td>
                                <p><span>AETHIOPS ANTIMONIALIS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>64</span></p>
                            </td>
                            <td>
                                <p><span>AETHIOPS </span><span>MINERALIS</span></p>
                            </td>
                            <td>
                                <p><span>Mercurius Sulphuratus Niger</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>&lt;::;/7</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>65</span></p>
                            </td>
                            <td>
                                <p><span>AETHIOPS MINER</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>66</span></p>
                            </td>
                            <td>
                                <p><span>AETHUSA CYNAPIUM</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>cf</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>cf</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>67</span></p>
                            </td>
                            <td>
                                <p><span>AGARICIN</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>]l</span></em></p>
                            </td>
                            <td>
                                <p><em><span>07</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>68</span></p>
                            </td>
                            <td>
                                <p><span>AGARICUS CAMPESTRIS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>69</span></p>
                            </td>
                            <td>
                                <p><span>AGARICUS EMETICUS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>70</span></p>
                            </td>
                            <td>
                                <p><span>AGARICUS MUSCARIUS</span></p>
                            </td>
                            <td>
                                <p><span>Amanita Muscaria</span></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>,I</span></em></p>
                            </td>
                            <td>
                                <p><em><span>f</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>71</span></p>
                            </td>
                            <td>
                                <p><span>AGARICUS PANTHERINA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>cf</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>72</span></p>
                            </td>
                            <td>
                                <p><span>AGARICUS PHALLOIDES</span></p>
                            </td>
                            <td>
                                <p><span>Amanita Phalloides</span></p>
                            </td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>73</span></p>
                            </td>
                            <td>
                                <p><span>AGARICUS PINICOLA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>74</span></p>
                            </td>
                            <td>
                                <p><span>AGAVE </span><span>AMERICANA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>75</span></p>
                            </td>
                            <td>
                                <p><span>AGNUS CASTUS</span></p>
                            </td>
                            <td>
                                <p><span>Vitex Agnus Castus</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>ij</span></em></p>
                                <p><em><span>if</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>76</span></p>
                            </td>
                            <td>
                                <p><span>AGRAPHIS NUTANS</span></p>
                            </td>
                            <td>
                                <p><span>Scilla Nurans</span></p>
                            </td>
                            <td>
                                <p><em><span>fl</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>77</span></p>
                            </td>
                            <td>
                                <p><span>AGRIMONIA EUPATORIA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>,f</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>78</span></p>
                            </td>
                            <td>
                                <p><span>AGROPYRUM REPENS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>cf</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>79</span></p>
                            </td>
                            <td>
                                <p><span>AGROSTEMNA GITHAGO</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>80</span></p>
                            </td>
                            <td>
                                <p><span>AGROSTIS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>81</span></p>
                            </td>
                            <td>
                                <p><span>AILANTHUS GLANDULOSUS</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>82</span></p>
                            </td>
                            <td>
                                <p><span>ALETRIN</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>83</span></p>
                            </td>
                            <td>
                                <p><span>ALETRIS FARINOSA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>,f</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>,/</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>84</span></p>
                            </td>
                            <td>
                                <p><span>ALFALFA</span></p>
                            </td>
                            <td>
                                <p><span>Medicago Saliva</span></p>
                            </td>
                            <td>
                                <p><em><span>,J</span></em></p>
                                <p><em><span>if</span></em></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><em><span>,J</span></em></p>
                                <p><em><span>if</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>85</span></p>
                            </td>
                            <td>
                                <p><span>AGROSTENA GITHA</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>
                                <p><span>ef</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <p><span>86</span></p>
                            </td>
                            <td>
                                <p><span>ALISMA PLANTAGO</span></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>
                                <p><em><span>J</span></em></p>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default HomeopathicProductCatalog