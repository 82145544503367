import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ProductGrid from './ProductGrid'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, NoDataFound, SideDrawer } from "../Library/Module";
import thumbnail from '../images/thumbnail.jpg'
import { replaceWithDash } from "../Library/Utility/Utility";
interface HomeProps {
    link: string;
}

const SubCategoryComp = ({ link }: HomeProps): JSX.Element => {
    const { mainCategory, breadcrumb, updateBreadcrumb, updateCatlogData, catlogData, productList } = useContext(ThemeContext)
    const { category, product_title } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [selectedData, setSelectedData] = useState<any>('')
    const [loading, setLoading] = useState<any>(true)



    const selectedProduct = useCallback((data) => {
        console.log("selectedProduct data", data)
        setSelectedData(data)
        setOpen(true)
    }, [])


    // const catagoryData = useMemo(() => {
    //     if (catlogData?.length !== 0) {
    //         return catlogData?.filter((item: any) => {
    //             return item?.category === category
    //         })
    //     }
    // }, [category, catlogData])

    const categoryTitle = useMemo(() => mainCategory?.find((i: any) => i?.slug === category), [category, mainCategory])
    console.log("categoryTitle", categoryTitle?.title)


    useEffect(() => {
        const data = [
            { "name": "Home", "link": "/", "icon": "" },
            { "name": "Product Category", "link": "/category", "icon": "" },
            { "name": categoryTitle?.title, "link": `/category/${category}`, "icon": "" }
        ]
        updateBreadcrumb(data)
    }, [category, categoryTitle?.title, updateBreadcrumb])

    console.log("mainCategory", mainCategory?.find((i: any) => i?.slug === category))


    const catagoryData = useMemo(() => {
        if (productList?.length !== 0) {
            return productList?.filter((item: any) => {
                return item?.category === category
            })
        }
    }, [productList, category])

    useEffect(() => {
        if (catagoryData?.length === 1) {
            navigate(`/category/${category}/${catagoryData?.[0]?.catalog}/${catagoryData?.[0]?.slug}`)
        }
    }, [catagoryData, category, navigate])

    return (
        <>
            {/* <div className={`banner banner2 ${category}`}>
                <div className="container">
                    <div className="banner_text">
                        <h3 className="changeCase">{categoryTitle?.title}</h3>
                    </div>
                </div>
            </div> */}
            <div className="">
                {breadcrumb}
            </div>
            <div className="categoryList subCategory">
                <div className="container pt-4 pb-4">
                    <div className="row">
                        {catagoryData?.length === 0 ? <NoDataFound></NoDataFound> :
                            catagoryData?.map((item: any) => {
                                return <>
                                    <div className="col-md-3 mb-3">
                                        <NavLink to={`/category/${category}/${item?.catalog}/${item?.slug}`}>
                                            <div className="inner">
                                                <div className="imagePlaceholder">
                                                    <img src={item?.image?.length ? process.env.react_app_base_url + "/" + item?.image?.[0] : thumbnail} alt="" title=""></img>
                                                </div>
                                                <div className="innerText">
                                                    <h3 className="pageTitle">{item?.title} </h3>
                                                    {/* <p className="subHeading">{replaceWithDash(item?.catalog)} </p> */}
                                                    {/* <NavLink className="btn btn-outline-primary" to={`/category/${category}/${item?.catalog}/${item?.slug}`}>View Details</NavLink> */}
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </>
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};
export default SubCategoryComp;
