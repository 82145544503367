/* eslint-disable no-empty-pattern */
import { NavLink } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from '../components/Footer';
import { useContext } from "react";
import { ThemeContext } from "../Context/Theme/Context";

interface AlertProps {
  children?: any;
  pageTitle?: string;
  breadcrumbList?: any;
}
const Wrapper = ({
  children,
  pageTitle,
  breadcrumbList,
}: AlertProps): JSX.Element => {
  const { orgUser, bgTextColor } = useContext(ThemeContext)
  return (
    <>
      <div style={{ background: `url(${bgTextColor})` }}>
        <div className="wrapper">
          <Header />
          {children}
          <Footer></Footer>
        </div>
      </div>
    </>
  );
};
export default Wrapper;
