import Facebook from '../images/social_facebook.png'
import Instagram from '../images/social_instagram.png'
import Twitter from '../images/social_twitter.png'
import Youtube from '../images/social_youtube.png'
import Icon1 from '../images/social_icon1.png'
import footer2 from '../images/logo.png'
import { NavLink, useLocation } from 'react-router-dom'
import { Button, SideDrawer } from '../Library/Module'
import ContactForm from './ContactForm'
import { useState } from 'react'
const Footer = () => {
    const [open, setOpen] = useState(false)
    const Inquiry = () => {
        setOpen(true)
    }

    const CloseDrawer = () => {
        setOpen(false)
    };

    const location = useLocation()
    console.log("download-catalogue", location.pathname.includes("download-catalogue"))
    const isDownloadCataloguePage = location.pathname.includes("download-catalogue")

    return (
        <>
            {/* {open &&
                <SideDrawer
                    size={'450px'}
                    pagetitle={`Download Catalog`}
                    action={CloseDrawer}>
                    <ContactForm col={'1'}></ContactForm>
                </SideDrawer>
            }
            {!isDownloadCataloguePage &&
                <div className='footerStrip' id='downloadCatalog'>
                    <div className='overlayFooterStrip'>
                        <div className='container '>
                            <div className='row justify-content-center align-content-center'>
                                <div className='col-md-9'>
                                    <p>Thank you for your interest in National Homeo Laboratory products. Please use the following form to download our detailed product catalogue. In case you wish to contact us, please get in touch with us at customercare.nhl@gmail.com or +91-033-22658232</p>
                                    <Button onClick={() => Inquiry()} buttonStyleType="secondary"> <span className="material-symbols-outlined"> description</span> Download Catalog </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            } */}
            <div className='footerSection'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='footerSectionInner'>
                                <ul className='listItem'>
                                    <li><NavLink to={'/about-us'}>About Us</NavLink></li>
                                    <li><NavLink to={'/category'}>Our Products</NavLink></li>
                                    <li> <NavLink to={'/download-catalogue'}>Download Catalogue</NavLink> </li>
                                    {/* <li><NavLink to={'/distributors'}>Distributors</NavLink></li> */}
                                    {/* <li><NavLink to={'/news'}>News</NavLink></li>
                                <li><NavLink to={'/blog'}>Blog</NavLink></li> */}
                                    <li><NavLink to={'/contact-us'}>Contact Us</NavLink></li>
                                    <li><NavLink to={'/cms/privacy-policy'}>Privacy Policy</NavLink></li>
                                    <li><NavLink to={'/cms/terms-conditions'}>Terms & Conditions</NavLink></li>
                                </ul>
                                <p>All our products are made in FDA compliant GMP certified Laboratories.</p>
                                {/* <p><NavLink to={'/'}><img className={'footerLogo'} src={footer2} title="" alt='' /></NavLink></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer