import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../Context/Theme/Context";
import Collection from '../images/new_collection.png'
import Collection2 from '../images/new_collection2.png'
import Collection3 from '../images/new_collection3.png'
import catDrappery from '../images/cat_drappery.png'
import catDrappery2 from '../images/cat_drappery.png'



import Wrapper from "./../Wrapper";
import { useParams } from "react-router-dom";
import { CMSDetails } from "../../servies/services";

const CMSTemplate = () => {

    const { title } = useParams()
    console.log("title", title)

    const [cmsDetailsAPI, setCmsDetailsAPI] = useState<any>([]);
    useEffect(() => {
        const getData = async () => {
            const APIResponse = await CMSDetails(title);
            if (APIResponse?.data?.isSuccess === true) {
                setCmsDetailsAPI(APIResponse?.data?.data);
            } else {
                console.error("something went wrong, please try after sometime.")
            }
        };
        getData();
    }, [title])


    return (
        <>
            <div className="banner banner2">
                <div className="container">
                    <div className="banner_text">
                        <h3 className="changeCase">{cmsDetailsAPI?.title}</h3>
                    </div>
                </div>
            </div>
            <div className="container pt-4 pb-4">
                <div className="row justify-content-center pb-3 pt-3">
                    <div className="col-md-6 col-sm-12">
                        <div dangerouslySetInnerHTML={{ __html: cmsDetailsAPI?.description }} />
                    </div>
                </div>
            </div>

        </>
    );
};
export default CMSTemplate;
