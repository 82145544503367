import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import Collection from '../images/new_collection.png'
import Collection2 from '../images/new_collection2.png'
import Collection3 from '../images/new_collection3.png'
import catDrappery from '../images/cat_drappery.png'
import catDrappery2 from '../images/cat_drappery.png'
import Wrapper from "./Wrapper";
import { NavLink } from "react-router-dom";
import { Button, Input } from "../Library/Module";
import ContactForm from "../components/ContactForm";
import { useLocation } from 'react-router-dom';


interface HomeProps {
    link: string;
}

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};


const CatalogusDownload = (): JSX.Element => {
    const { orgUser } = useContext(ThemeContext)
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = () => {
        alert("Form has been submitted successfully")
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])




    return (
        <>
            {/* <div className="banner banner2">
                <div className="container">
                    <div className="banner_text">
                        
                    </div>
                </div>
            </div> */}

            <div className='footerStrip footerStrip2' id='downloadCatalog'>
                <div className='overlayFooterStrip'>
                    <div className='container '>
                        <div className='row '>
                            <div className='col-md-12'>
                            <h3 className="changeCase">Download Catalogue</h3>
                                <p>Thank you for your interest in National Homeo Laboratory products. Please use the following form to download our detailed product catalogue. </p>
                                <p>In case you wish to contact us, please get in touch with us at <a href="mailto:customercare@nationalhomeolab.co.in"> customercare@nationalhomeolab.co.in </a> or +91 89109 63130</p>
                            </div>
                            <div className='col-md-12'>
                                <ContactForm col='2'></ContactForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CatalogusDownload;
