import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ProductGrid from './ProductGrid'
import Wrapper from "./Wrapper";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, NoDataFound, SideDrawer, Slider } from "../Library/Module";
import { getCollection } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";
import subFooter from '../images/subFooter.png';
import ContactForm from "../components/ContactForm";
import qualityImg from '../images/quality.png';
import CategoryBox from "./CategoryBox";
interface HomeProps {
    link: string;
}

const ProductDetails = ({ link }: HomeProps): JSX.Element => {
    const { mainCategory, updateBgColor, breadcrumb, updateBreadcrumb, catlogData, productList } = useContext(ThemeContext)
    const { category, subCategory, product_title } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState<any>(false)
    const [data, setData] = useState<any>([])
    const [selectedData, setSelectedData] = useState<any>('')
    const [bgImage, setBgImage] = useState<any>('')



    const selectedProduct = useCallback((data) => {
        setSelectedData(data)
        setOpen(true)
    }, [])

    const CloseDrawer = () => {
        setOpen(false)
    };

    const applyBtn = useCallback((data) => {
        setBgImage(data?.image)
        updateBgColor?.(data?.image)
    }, [updateBgColor])

    const closepopup = useCallback(() => {
        setSelectedData('')
    }, [])

    const catagoryData = useMemo(() => {
        if (productList?.length !== 0) {
            return productList?.find((item: any) => {
                return item?.slug === product_title
            })
        }
    }, [productList, product_title])

    const categoryTitle = useMemo(() => mainCategory?.find((i: any) => i?.slug === category), [category, mainCategory])
    console.log("categoryTitle", categoryTitle?.title)

    const categorySubTitle = useMemo(() => catlogData?.find((i: any) => i?.slug === subCategory), [catlogData, subCategory])
    console.log("categorySubTitle", categorySubTitle?.title)

    useEffect(() => {
        const data = [
            { "name": "Home", "link": "/", "icon": "" },
            { "name": "Product Category", "link": "/category", "icon": "" },
            { "name": categoryTitle?.title, "link": `/category/${category}`, "icon": "" },
            { "name": catagoryData?.title, "link": `/category/${category}/${subCategory}/${product_title}`, "icon": "" }

        ]
        updateBreadcrumb(data)
    }, [catagoryData?.title, category, categorySubTitle?.title, categoryTitle?.title, product_title, subCategory, updateBreadcrumb])



    const Inquiry = () => {
        setOpen(true)
    }

    return (
        <>
            <div className="productDetails">
                {/* <div className={`banner banner2 ${category}`}>
                    <div className="container">
                        <div className="banner_text">
                            <h3 className="changeCase">{catagoryData?.title}</h3>
                        </div>
                    </div>
                </div> */}
                <div className="">
                    {breadcrumb}
                </div>
                <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="mobile">
                                <h3>{catagoryData?.title}</h3>
                            </div>
                            <Slider title={catagoryData?.title} category={catagoryData?.category} images={catagoryData?.image}></Slider>
                        </div>
                        <div className="col-md-7">
                            <div className="descriptionData">
                                <div className="desktop">
                                    <h3>{catagoryData?.title}</h3>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: catagoryData?.description }} />
                                <Button onClick={() => Inquiry()} buttonStyleType="secondary"> <span className="material-symbols-outlined"> description</span> Enquire Now </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>

                <div className="container">
                    <div className="row">
                        {catagoryData?.pack_sizes?.length > 12 &&
                            <div className="col-md-5">
                                <div className="innerDesc">
                                    <h5>Pack Sizes</h5>
                                    <div dangerouslySetInnerHTML={{ __html: catagoryData?.pack_sizes }} />
                                </div>
                            </div>
                        }
                        {catagoryData?.dosage?.length > 12 &&
                            <div className="col-md-7">
                                <div className="descriptionData">
                                    <div className="innerDesc">
                                        <h5>Dosage</h5>
                                        <div dangerouslySetInnerHTML={{ __html: catagoryData?.dosage }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/* <div className="container pt-4 pb-4">
                    <div className="row">
                        <div className="col-md-6">
                            <h5>QUALITY CONTROL</h5>
                            <p>With the traditional Hahnemannian process of medicine manufacturing mixed
                                with the researches of the company - provides in India, World class
                                Homoeopathic medicines. The Quality control team of the company ensures that
                                the products meet the &#39;World Standard&#39;. The company does not compromise with
                                the quality control - the entire factory area is dust free with Class A Air Handling
                                Unit, automated machines in required places &amp; strict safely measures in the
                                parts, where works are carried out manually.</p>
                            <h5>CERTIFICATIONS</h5>
                            <p>National has the certification of ISO 9001:2000. They have also acquired the GMP certificate - the ultimate quality standard in medicine. The company organises Homoeopathic Doctor's conference at different venues for the betterment of homoeopathic practices & research.</p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img src={qualityImg} title="" alt="" />
                        </div>
                    </div>
                </div> */}

                <div className="clearfix"></div>
                {/* <div className="subFooter">
                    <img src={subFooter} alt="" title="" />
                </div> */}

                <CategoryBox></CategoryBox>
            </div>
            {open &&
                <SideDrawer
                    size={'450px'}
                    pagetitle={`Enquire for ${catagoryData?.title}`}
                    action={CloseDrawer}>
                    <ContactForm col={'1'}></ContactForm>
                </SideDrawer>
            }</>
    )
}

export default ProductDetails