import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import ProductGrid from './ProductGrid'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, NoDataFound, SideDrawer } from "../Library/Module";
import thumbnail from '../images/thumbnail.jpg'
import { replaceWithDash } from "../Library/Utility/Utility";

interface HomeProps {
    link: string;
}

const ProductList = ({ link }: HomeProps): JSX.Element => {
    const { mainCategory, breadcrumb, updateBreadcrumb, updateCatlogData, catlogData, productList } = useContext(ThemeContext)
    const { category, subCategory } = useParams()


    const catagoryData = useMemo(() => {
        if (productList?.length !== 0) {
            return productList?.filter((item: any) => {
                return item?.catalog === subCategory
            })
        }
    }, [productList, subCategory])

    const catagoryData12 = useMemo(() => {
        if (mainCategory?.length !== 0) {
            return mainCategory?.filter((item: any) => {
                console.log("asdasd", mainCategory)
                return item?.slug === subCategory
            })
        }
    }, [mainCategory, subCategory])

    const categoryTitle = useMemo(() => mainCategory?.find((i: any) => i?.slug === category), [category, mainCategory])
    console.log("categoryTitle", categoryTitle?.title)

    const categorySubTitle = useMemo(() => catlogData?.find((i: any) => i?.slug === subCategory), [catlogData, subCategory])
    console.log("categorySubTitle", categorySubTitle?.title)

    useEffect(() => {
        const data = [
            { "name": "Home", "link": "/", "icon": "" },
            { "name": "Product Category", "link": "/category", "icon": "" },
            { "name": categoryTitle?.title, "link": `/category/${category}`, "icon": "" },
            { "name": categorySubTitle?.title, "link": `/category/${category}/${subCategory}`, "icon": "" }
        ]
        updateBreadcrumb(data)
    }, [category, categorySubTitle?.title, categoryTitle?.title, subCategory, updateBreadcrumb])

    return (
        <>
            
            <div className="">
                {breadcrumb}
            </div>
            <div className="categoryList ProductListing">
                <div className="container pt-4 pb-4">
                    <div className="row">
                        {catagoryData?.length === 0 ? <NoDataFound></NoDataFound> :
                            catagoryData?.map((item: any) => {
                                return <>
                                    <div className="col-md-3 mb-3">
                                        <NavLink to={`/category/${category}/${subCategory}/${item?.slug}`}>
                                            <div className="inner">
                                                <div className="imagePlaceholder">
                                                    <img src={item?.image?.length ? process.env.react_app_base_url + "/" + item?.image?.[0] : thumbnail} alt="" title=""></img>
                                                </div>
                                                <div className="innerText">
                                                    <h3 className="pageTitle">{item?.title} </h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </>
                            })}
                    </div>
                </div>
            </div>

        </>
    );
};
export default ProductList;
